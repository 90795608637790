<template>
  <div class="wrap">
    <div class="filter">
      <el-button class="btn" @click="add_goods">添加商品</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="图片">
        <template slot-scope="scope">
          <img :src="scope.row.img" width="100" />
        </template>
      </el-table-column>

      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row)"
            >编辑</el-button
          >
          <el-button class="btn" size="mini" @click="del_goods(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="150" title="编辑信息" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="编辑信息" name="essentialInformation">
          <el-form :model="formamend">
            <el-form-item label="商品图片：" label-width="120">
              <el-upload
                class="avatar-uploader"
                action="/api/kapin_ee/user/v1/files_upload/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img
                  style="width: 100px"
                  v-if="formamend.img"
                  :src="formamend.img"
                  class="avatar"
                />
                <div v-else class="ii">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="商品名称：" label-width="120">
              <el-input type="text" v-model="formamend.title"></el-input>
            </el-form-item>
            <el-form-item label="兑换积分：" label-width="120">
              <el-input type="number" v-model="formamend.jifen"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="添加信息" name="add">
          <el-form :model="formadd">
            <el-form-item label="商品图片：" label-width="120">
              <el-upload
                class="avatar-uploader"
                action="/api/kapin_ee/user/v1/files_upload/"
                :show-file-list="false"
                :on-success="addhandleAvatarSuccess"
              >
                <img
                  style="width: 100px"
                  v-if="formadd.img"
                  :src="formadd.img"
                  class="avatar"
                />
                <div v-else class="ii">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="商品名称：" label-width="120">
              <el-input type="text" v-model="formadd.title"></el-input>
            </el-form-item>
            <el-form-item label="兑换积分：" label-width="120">
              <el-input type="number" v-model="formadd.jifen"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btn" @click="amend">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_goods,
  update_goods,
  delete_goods,
} from "@/api/hr24_api/api_all.js";
import { showCity } from "@/api/resume";
import axios from "axios";

export default {
  data() {
    return {
      search_str: "",

      // 表单数据处理
      tableData_cell: [
        { title: "产品名称", value: "title" },
        { title: "兑换积分", value: "jifen" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        id: "",
        title: "",
        img: "",
        jifen: "",
        license: "",
      },
      formadd: {
        id: "",
        title: "",
        img: "",
        jifen: "",
        license: "",
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",
      //总页码
      pageTotal: null,
      pageNow: 1,

      //审核状态
      colorStatus: [
        { color: "#ccc", type: "审核中" },
        { color: "#FF435A", type: "已发货" },
      ],
      status: 0,
    };
  },
  mounted() {
    this.pageNow = 1;

    get_goods({}).then((res) => {
      if (!res.code) {
        if (res.data.length) {
          res.data.map((item) => {
            console.log(item);
            // item.img = "../../static/goods/" + item.img;
          });
        }
        console.log("ssssssssssss");
        console.log(res.data);

        this.tableData = res.data;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      console.log(res);
    });
  },
  methods: {
    //下拉菜单
    commandHandle(command) {
      console.log(command);

      this.status = command;

      this.search(1);
    },

    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    // 删除产品
    del_goods(e) {
      if (confirm("确定要删除吗？") == true) {
        let id = e.id;
        let obj = {
          g_id: e.id,
        };

        delete_goods(obj).then((res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 添加商品
    onSubmit() {
      console.log("dddddddddd onSubmit");
      this.formadd;

      if (this.formadd.title == "" || this.formadd.jifen == "") {
        this.$message.error("请填写信息！");
        return;
      }

      let obj = {
        g_id: 0,
        title: this.formadd.title,
        img: this.formadd.license,
        jifen: this.formadd.jifen,
      };

      update_goods(obj).then((res) => {
        if (!res.code) {
          this.dialogFormVisible = false;
          this.$message.success(res.msg);
          this.search(1);
        } else {
          this.$message.error(res.msg);
        }
      });
      return false;
    },

    // 上传图片
    handleAvatarSuccess(res, file) {
      this.formamend.img = file.response.file_url;
      this.formamend.license = file.response.file_id;

      console.log("图片地址：" + this.formamend.license);
    },

    // 上传图片
    addhandleAvatarSuccess(res, file) {
      this.formadd.img = file.response.file_url;
      this.formadd.license = file.response.file_id;

      console.log("图片地址：" + this.formadd.license);
    },

    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search(e) {
      console.log("dddddddddd search");

      get_goods({}).then((res) => {
        if (!res.code) {
          if (res.data.length) {
            res.data.map((item) => {
              console.log(item);
              // item.img =   item.img;
            });
          }
          console.log("ssssssssssss");
          console.log(res.data);

          this.tableData = res.data;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      });
    },

    add_goods(e) {
      console.log("sssssssssssss");
      this.activeName = "add";
      this.dialogFormVisible = true;
      this.formamend = {
        id: 0,
      };

      console.log(this.formamend);
    },

    redact(e) {
      console.log("sssssssssssss");
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.formamend = {
        license: e.img_url,
        id: e.id,
        title: e.title,
        img: e.img,
        jifen: e.jifen,
      };

      console.log(this.formamend);
    },

    amend() {
      console.log("确认");

      if (this.activeName == "essentialInformation") {
        this.formamend;

        if (this.formamend.title == "" || this.formamend.jifen == "") {
          this.$message.error("请填写信息！");
          return;
        }

        let obj = {
          g_id: this.formamend.id,
          title: this.formamend.title,
          img: this.formamend.license,
          jifen: this.formamend.jifen,
        };

        update_goods(obj).then((res) => {
          if (!res.code) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
        return false;
      } else if (this.activeName == "add") {
        this.onSubmit();
        return false;
      } else {
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: auto;
  margin-right: 10px;
}
.el-select > .el-input {
  width: 100px !important;
}
.el-input__inner {
  width: 100px !important;
}
.el-form-item {
  width: 50%;
  float: left;
}

.get-btn {
  float: left;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

.avatar-uploader .el-upload {
  border: 1px dashed #ccc;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.ii {
  width: 100px;
  height: 100px;
  border: solid 1px #ccc;
  line-height: 100px;
  text-align: center;
}
</style>
